import { replaceNewlines } from '../../util/replaceNewlines';

export const mapGifItem = data => ({
  open: false,
  id: data.pk,
  signature: data.gif_interpretation_summary['Sygnatura'],
  date: data.gif_interpretation_summary['Data wydania'],
  summary: {
    ruling: replaceNewlines(data.ruling_content),
    motives: replaceNewlines(data.motives_content),
  },
  fileLink: data.file_link,
  link: {
    name: 'gif',
    image: 'gif',
    value: data.url,
  },
});
