export const gifFilters = [
  {
    value: '',
    label: 'numer',
    name: 'number',
    type: 'text',
  },
  {
    value: '',
    label: 'rozstrzygnięcie',
    name: 'ruling',
    type: 'text',
    highlightKeyword: 'ruling_keyword',
  },
  {
    value: '',
    label: 'uzasadnienie',
    name: 'motives',
    type: 'text',
    highlightKeyword: 'motives_keyword',
  },
];
