<template>
  <div :class="{ narrow }" v-escape="closeItems">
    <div class="table-header tablet-desktop mb-4">
      <div class="sig">sygnatura</div>
      <div class="date">data wydania</div>
    </div>
    <div v-for="(item, index) in items" :key="item.id" :id="item.slug">
      <gif-item
        @toggleOpen="event => toggleItem(index, event)"
        :searchHighlights="searchHighlights"
        :open="item.open"
        :listItem="item"
        :narrow="narrow"
        ref="item"
      />
    </div>
  </div>
</template>

<script>
import GifItem from '../GifItem/GifItem';

export default {
  components: {
    GifItem,
  },
  props: {
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    items: Array,
    narrow: Boolean,
  },
  watch: {
    narrow(value) {
      if (value) {
        this.items.forEach(item => {
          item.open = false;
        });
      }
    },
  },
  methods: {
    closeItems() {
      this.items.forEach(item => {
        item.open = false;
      });
    },
    toggleItem(index, event = null) {
      if (event && (event.ctrlKey || event.button === 1)) {
        if (event.type === 'mousedown') {
          event.preventDefault();
        } else {
          const route = this.$router.resolve({
            name: 'gif-item',
            params: { slug: this.items[index].slug },
          });
          console.log(route);
          window.open(window.location.origin + route.href, '_blank').focus();
        }
      } else if (event.type === 'mouseup') {
        this.items.forEach((item, itemIndex) => {
          if (itemIndex === index) {
            item.open = !item.open;
            this.$emit('toggle-item', item.open);
          } else {
            item.open = false;
          }
        });
      }
    },
    fixScrollPosition(index) {
      setTimeout(() => {
        const scroll = this.$store.getters['user/isLoggedIn'] ? 110 : 145;
        const fromTop = this.$refs.item[index].$el.getBoundingClientRect().top;
        window.scrollTo(0, window.scrollY - (scroll - fromTop));
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  padding: 1rem;
  padding-right: 7.5rem;
  border-bottom: 2px solid $gray-5;
  color: $gray-2;
  position: relative;
  z-index: 10;
}

.sig {
  width: 50%;
}
.date {
  width: 50%;
}
</style>
