<template>
  <div>
    <list-search
      class="mb-4"
      :searching="searching"
      @collapse="v => (advancedCollapsed = v)"
    />
    <div class="item-list">
      <div v-if="loading">
        <base-spinner large class="mb-32" />
      </div>
      <div v-else class="mb-8 mb-tablet-32">
        <search
          v-if="(highlightQuery || advanced) && activeSearch"
          searchApi="gif_search"
          :filters="gifFilters"
          :mapItems="mapGifItem"
          :advancedCollapse="advancedCollapsed"
          @advanced-open="advancedCollapsed = false"
          sublist
          #default="{items, advanced}"
          @loading="
            v => {
              this.searching = v;
            }
          "
        >
          <gif-list
            :items="items"
            :searchHighlights="{
              query: `v0/gif/load_search_highlights/:id?${highlightQuery}`,
              keywords: highlightKeywords,
            }"
            :narrow="advanced && !advancedCollapsed"
            @toggle-item="advancedCollapsed = $event"
            class="mb-4"
          />
        </search>
        <template v-else>
          <gif-list :items="items" class="mb-4" />

          <base-more-button
            v-if="items.length < count"
            @click="loadNext"
            :loading="loadingPage"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import GifList from './GifList';
import { mapGifItem } from './mapGifItem';
import ListSearch from '@/views/Search/components/ListSearch.vue';
import { gifFilters } from '@/views/Search/pages/gifFilters';
import Search from '@/views/Search/Search.vue';
import {
  highlightQuery,
  highlightKeywords,
} from '@/views/Search/util/highlights';

export default {
  components: {
    Search,
    ListSearch,
    GifList,
  },
  metaInfo() {
    return {
      title: 'GIF',
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  data() {
    return {
      gifFilters,
      mapGifItem,
      loading: false,
      loadingPage: false,
      items: [],
      page: 1,
      count: 0,
      advancedCollapsed: false,
      searching: false,
    };
  },
  computed: {
    advanced() {
      return this.$route.query.advanced;
    },
    activeSearch() {
      return this.$store.state.isSearchActive;
    },
    highlightQuery: function() {
      return this.$route && this.$route.query && this.$route.query.combo
        ? `combo_keyword=${this.$route.query.combo}`
        : highlightQuery(gifFilters);
    },
    highlightKeywords: function() {
      return this.$route && this.$route.query && this.$route.query.combo
        ? [this.$route.query.combo]
        : highlightKeywords(gifFilters);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.loadPage();
      this.loading = false;
    },
    async loadPage() {
      const url =
        this.page === 1 ? 'v1/gif_news/' : `v1/gif_news/?page=${this.page}`;
      const { data } = await this.$api.get(url);
      this.count = data.count;
      this.page++;
      this.items = this.items.concat(data.results.map(mapGifItem));
    },
    async loadNext() {
      const scrollPosition = Math.round(window.scrollY);
      this.loadingPage = true;
      await this.loadPage();
      this.loadingPage = false;
      this.$nextTick(() => {
        window.scrollTo(0, scrollPosition);
      });
    },
  },
};
</script>
